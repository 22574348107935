<template>
  <b-container class="mt-3" fluid="md">
    <b-row>
      <b-col>
        <h3 v-if="entity">{{ entity.name }}</h3>
        <h6 v-if="entity">{{ formatTimestamp(entity.timestamp) }}</h6>
        <div>
          <router-link v-if="entity" :to="`/event/${entity.id}/edit`" class="mr-3">Edit</router-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8" sm="12">
        <template v-if="entity">
          <AttributeTable v-if="hasAttributes" class="mt-4" :entity="entity" />
          <Payload class="mt-4" type="Event" :entity="entity"/>
        </template>
      </b-col>
      <b-col>
        <template v-if="entity">
           <RelatedEventEntities class="mt-4" :entity="entity" />
        </template>
      </b-col>
    </b-row>
  </b-container>  
</template>

<script>
import axios from 'axios';

import AttributeTable from '../components/AttributeTable.vue'
import Payload from '../components/Payload.vue'
import RelatedEventEntities from '../components/RelatedEventEntities.vue'

export default {
  name: 'EntityPage',
  
  data () {
    return {
      entity:  null,
      hasAttributes: false, 
    };
  },

  components: { 
    AttributeTable,
    Payload,
    RelatedEventEntities,
  },

  methods: {
    getEntity() {
      axios.get('/api/event/' + this.$route.params.id)
        .then((res) => {
          this.entity = res.data.data;
          this.hasAttributes = Object.keys(this.entity.attr_values).length > 0;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    formatTimestamp(date) {
      return this.$moment(date).format("MMMM DD YYYY");
    },
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    currentUniverse () {
      return this.$store.getters.currentUniverse;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
    this.getEntity();
  }
}
</script>
