<template>
  <div>
    <div><h5>Entity Types</h5></div>
    <div v-if="entity_types.length > 0">
      <router-link v-for="entity_type in entity_types" 
        :key="entity_type.id" 
        :to="`/entitytype/edit/${entity_type.id}`"
        class="mr-3"
      >
        {{ entity_type.display_name }}
      </router-link> 
    </div>
    <div v-else><em>No entity types have been created yet</em></div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EntityTypeList',
  
  data () {
    return {
      entity_types: []
    }
  },

  methods: {
    getData() {
      axios.get('/api/entitytype/list')
        .then((res) => {
          this.entity_types = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    }
  },

  created () {
    this.getData();
  }
}
</script>

<style scoped>
</style>

