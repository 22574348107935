<template>
  <div class="mx-auto w-50 mt-3">
    <div><h3>Add Universe</h3></div>
    <b-alert v-model="showAlert" class="mt-3" variant="danger" dismissible>{{ alertMessage }}</b-alert>
    <b-form class="mt-1" @submit="onSubmit" @reset="onReset">
       <b-form-group
        id="input-group-1"
        label="Name"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.name"
          type="text"
          required
          placeholder="Enter universe name"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Description" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="form.description"
          placeholder="Enter description"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
      &nbsp;
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddUniversePage',
  
  components: {   
  },

  data() {
      return {
        form: {
          name: '',
          description: '',
        },
        showAlert: false,
        alertMessage: '',
      }
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      var path = '/api/universe/add';
        axios.post(path, this.form)
          .then((res) => {
            if (res.data.status == "ok") {
              let session = res.data.data;
              this.$store.commit("changeSession", session);
              this.$store.commit("setSessionDirty", true);
            } else {
              this.alertMessage = res.data.error_message;
              this.showAlert = true;
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.showOverlay = false;
          });
    },
    
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.form.name = ''
      this.form.description = ''
      this.alertMessage = ''
      this.showAlert = false
    }
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
  }
}
</script>

<style scoped>
</style>

