import { render, staticRenderFns } from "./LinkTypeList.vue?vue&type=template&id=204e0ec0&scoped=true&"
import script from "./LinkTypeList.vue?vue&type=script&lang=js&"
export * from "./LinkTypeList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204e0ec0",
  null
  
)

export default component.exports