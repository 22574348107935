<template>
  <div class="mx-auto w-50 mt-3">
    <div><h3>Log In</h3></div>
    <b-alert v-model="showAlert" class="mt-3" variant="danger" dismissible>{{ alertMessage }}</b-alert>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-form @submit="onSubmit" @reset="onReset">
        <b-form-group
          id="input-group-1"
          label="Username:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.username"
            required
            placeholder="Username"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Password:" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.password"
            required
            type="password"
            placeholder="Password"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">Submit</b-button>
        &nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      showOverlay: false,
      showAlert: false,
      alertMessage: '',
      form: {
          username: '',
          password: '',
      }
    }
  },
  name: 'LogInPage',
  components: {
  },
  methods: {
      onSubmit(evt) {
        this.showOverlay = true;
        evt.preventDefault()
        var path = '/api/login';
        axios.post(path, this.form)
          .then((res) => {
            if (res.data.status == "ok") {
              let session = res.data.data;
              this.$store.commit("changeSession", session);
              this.$store.commit("setSessionDirty", true);
              this.$router.push('/')
            } else {
              this.alertMessage = res.data.error_message;
              this.showAlert = true;
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.showOverlay = false;
          });
      },
      
      onReset(evt) {
        evt.preventDefault()
        // Reset our form values
        this.form.username = '';
        this.form.password = '';
        this.showAlert = false;        
      }
  }
}
</script>

<style>
</style>
