import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        session: null,
        sessionDirty: false
    },

    getters: {
        loggedIn: state => {
            return state.session && state.session.is_active;
        },

        userName: state => {
            return state.session ? state.session.username : null;
        },
        
        currentUniverse: state => {
            return state.session ? state.session.current_universe : null;
        },

        userUniverses: state => {
            return state.session ? state.session.universes : [];
        }
    },

    mutations: {
        changeSession(state, session) {
            state.session = session;
        },

        setSessionDirty(state, dirty) {
            state.sessionDirty = dirty;
        },
    },

    actions: {}
});
