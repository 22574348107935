<template>
  <b-container class="mt-3">
    <div v-if="loggedIn && currentUniverse">
      <EntityList v-if="loggedIn" />
      <EventList class="mt-4" v-if="loggedIn" />
      <Timeline class="mt-4" v-if="loggedIn" />
    </div>
    <div v-else><em>Log in and select a universe first</em></div>
  </b-container>  
</template>

<script>
import EntityList from '../components/EntityList.vue'
import EventList from '../components/EventList.vue'
import Timeline from '../components/Timeline.vue'

export default {
  name: 'DefaultPage',
  
  components: {   
    EntityList,
    EventList,
    Timeline
  },

  methods: {
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    currentUniverse () {
      return this.$store.getters.currentUniverse;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
  }
}
</script>

<style scoped>
</style>

