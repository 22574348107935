<template>
  <b-container class="mt-3" fluid="md">
    <b-row>
      <b-col>
        <h3>Admin</h3>
        <div>
          <router-link to="/entitytype/add">Add Entity Type</router-link>
          <router-link to="/linktype/add" class="ml-3">Add Link type</router-link>
          <router-link to="/eventtype/add" class="ml-3">Add Event type</router-link>
          <router-link to="/universe/add" class="ml-3">Add Universe</router-link>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col  md="8" sm="12">
        <EntityTypeList v-if="loggedIn" />
        <LinkTypeList class="mt-4" v-if="loggedIn" />
        <EventTypeList class="mt-4" v-if="loggedIn" />
      </b-col>
      <b-col>
      </b-col>
    </b-row>
  </b-container>  
</template>

<script>
//import axios from 'axios';
import EntityTypeList from '../components/EntityTypeList.vue'
import EventTypeList from '../components/EventTypeList.vue'
import LinkTypeList from '../components/LinkTypeList.vue'

export default {
  name: 'AdminPage',
  
  data () {
    return {
    };
  },

  components: {
    EntityTypeList,
    EventTypeList,
    LinkTypeList
  },

  methods: {
    getData() {
    }
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    currentUniverse () {
      return this.$store.getters.currentUniverse;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
    this.getData();
  }
}
</script>

<style scoped>
</style>

