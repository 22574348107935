<template>
  <div>
    <h5>Notes</h5>
    <b-button class="mb-3" variant="primary" v-b-modal.add-note-modal>Add note</b-button>
    <b-modal
      id="add-note-modal"
      title="Add Note"
      size="xl"
      centered
      scrollable
      @ok="onModalOK"
    >
      <AddEditNote
        ref="noteEditor"
        :type="type"
        :entityId="entity.id"
        :isEdit="isEdit"
        :editNote="editNote"
        @noteAdded="handleNoteAdded"
      />
    </b-modal>
    
    <div v-if="notes.length > 0">
      <div :key="index" v-for="(note, index) in notes">
        <Note :note="note" :index="index" @edit="handleEdit" @delete="handleDelete" />
      </div>
    </div>
    <div v-else>
      <em>There are no notes yet.</em>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Note from './Note.vue'
import AddEditNote from './AddEditNote'

export default {
  name: 'Payload',
  props: {
      entity: Object,
      type: String
  },

  data () {
    return {
      notes: [],
      isEdit: false,
      editNote: null
    }
  },

  components: {
    AddEditNote,
    Note,
  },

  methods: {
    onModalOK(evt) {
      evt.preventDefault();
      this.$refs.noteEditor.saveNote();
      this.resetModal();
    },

    onModalCancel() {
      this.resetModal();  
    },

    resetModal() {
      this.isEdit = false;
      this.editNote = null;  
    },

    handleNoteAdded(entity) {
      this.$bvModal.hide('add-note-modal');
      this.notes = entity.payload.notes;
    },

    handleEdit(index) {
      this.isEdit = true;
      this.editNote = this.notes[index];
      this.$bvModal.show('add-note-modal');
    },

    getDeletePath() {
      if (this.type === 'Entity') {
        return '/api/entity/' + this.entity.id + '/notes/delete';
      } else if (this.type === 'Event') {
        return '/api/event/' + this.entity.id + '/notes/delete';
      } else {
        return '/api/link/' + this.entity.id + '/notes/delete';
      }
    },

    handleDelete(index) {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(value => {
          if (!value) {
            return;
          }
          
          axios.post(this.getDeletePath(), {'timestamp': this.notes[index].timestamp})
            .then((res) => {
              if (res.data.status == "ok") {
                this.notes = res.data.data.payload.notes;
              } else {
                console.error(res.data.error_message)
              }
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
            });
              
        })
        .catch(err => {
          console.error(err);
        })
    }
  },

  created () {
    this.notes = this.entity.payload.notes;
  }
}
</script>

<style scoped>
</style>

