<template>
  <div id="app">
    <Navbar />
    <!-- component matched by the route will render here -->
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'

export default {
  name: 'App',

  components: {
    Navbar,
  },
}
</script>

<style>
#app {
}
</style>
