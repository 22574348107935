<template>
  <div class="mx-auto w-50 mt-3">
    <div v-if="isEdit"><h3>Edit Event</h3></div>
    <div v-else><h3>Add Event</h3></div>
    <b-alert v-model="showAlert" class="mt-3" variant="danger" dismissible>{{ alertMessage }}</b-alert>

    <b-form class="mt-1" @submit.prevent="onSubmit" @reset.prevent="onReset">
      <b-form-group
        id="input-group-1"
        label="Name"
        label-for="name-input"
      >
        <b-form-input
          v-model="name"
          placeholder="Enter event name"
          type="text"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-2"
        label="Type"
        label-for="type-select"
      >
        <b-form-select
          id="type-select"
          v-model="selected"
          :options="options"
          :disabled="isEdit"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>-- Select an event type --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <template v-if="selected && entityTypes[selected].length > 0">
      <b-form-group
        v-for="attribute in entityTypes[selected]"
        :id="`input-group-${attribute.name}`"
        :label="attribute.display_name + (attribute.required === 'True' ? '*' : '')"
        :label-for="`input-${attribute.name}`"
        :key="attribute.name"
      >
        <b-form-input
          v-model="attributeValues[selected][attribute.name]"
          :id="`input-${attribute.name}`"
          :type="getFormInputTypeFor(attribute)"
          :required="attribute.required === 'True'"
          :placeholder="`Enter ${attribute.display_name.toLowerCase()}`"
        ></b-form-input>
      </b-form-group>
      </template>

      <b-form-group
        id="input-group-3"
        label="Timestamp"
        label-for="timestamp-input"
      >
        <b-form-input
          id="timestamp-input"
          v-model="timestamp"
          type="date"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        label="Related entities"
        label-for="entities-select"
      >
        <b-form-select
            id="entities-select"
            :options="entityOptions"
            v-model="selectedEntities"
            multiple
            :select-size="4"
            required
        ></b-form-select>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
      &nbsp;
      <b-button type="reset" variant="danger">Reset</b-button>
      &nbsp;
      <b-link v-if="isEdit" @click="onDeleteClick">Delete</b-link>
    </b-form>
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddEditEventPage',
  
  components: {   
  },

  data() {
      return {
        name: null,
        timestamp: null,
        selected: null,
        selectedEntities: [],
        options: [],
        entityOptions: [],
        entityTypes: {},
        attributeValues: {},
        showAlert: false,
        alertMessage: '',
        isEdit: 'id' in this.$route.params,
        entity: null
      }
  },

  methods: {
    onSubmit() {
       var path = this.isEdit 
        ? '/api/event/' + this.$route.params.id + '/update'
        : '/api/events/add';
      var formData = {
        'name' : this.name,
        'type' : this.selected,
        'timestamp' : this.timestamp,
        'entities' : this.selectedEntities,
        'attr_values' : this.attributeValues[this.selected]
      };
      axios.post(path, formData)
        .then((res) => {
          if (res.data.status == "ok") {
            let redirPath = '/event/' + (this.isEdit 
              ? this.$route.params.id
              : res.data.data);
            console.info(redirPath);
            this.$router.push(redirPath);
          } else {
            this.alertMessage = res.data.error_message;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        });
    },
    
    onReset() {
      // Reset our form values
      this.name = null;
      this.selected = null;
      this.alertMessage = ''
      this.showAlert = false
    },

    getFormData() {
      axios.get('/api/entities/list')
        .then((res) => {
          let entities = res.data.data;
          for (let entity of entities) {
            this.entityOptions.push({
              'value' : entity.id,
              'text' : entity.name
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });

      axios.get('/api/eventtypes/list')
        .then((res) => {
          let entityTypeOptions = [];
          for (let entityType of res.data.data) {
            entityTypeOptions.push({
              'value' : entityType.id,
              'text' : entityType.display_name
            });
            
            this.entityTypes[entityType.id] = entityType;
            // Set attribute defaults
            this.attributeValues[entityType.id] = {};
          }
          this.$data.options = entityTypeOptions;

          // If isEdit load entity data
          if (this.isEdit) {
            axios.get('/api/event/' + this.$route.params.id)
              .then((res) => {
                if(res.data.status == "ok") {
                  this.entity = res.data.data;
                  this.name = this.entity.name;
                  this.timestamp = this.toJSDate(this.entity.timestamp);
                  this.selected = this.entity.type;
                  // Fill attributes
                  for (let attr_name in this.entity.attr_values) {
                    let attr_value = this.entity.attr_values[attr_name];
                    this.attributeValues[this.selected][attr_name] = attr_value;
                  }
                  // Select related entities
                  for (let relatedEntityIndex in this.entity.related_entities) {
                    let relatedEntity = this.entity.related_entities[relatedEntityIndex];
                    this.selectedEntities.push(relatedEntity);
                  }
                } else {
                  this.alertMessage = res.data.error_message;
                  this.showAlert = true;
                }
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
              });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    onDeleteClick() {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(value => {
          if (!value) {
            return;
          }
          axios.post('/api/event/' + this.$route.params.id + '/delete')
            .then((res) => {
              if (res.data.status == "ok") {
                this.$router.push('/');
              } else {
                // TODO: errro handling
                console.error(res.data.error_message);
              }
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
            });
        })
        .catch(err => {
          console.error(err);
        })
    },

    getFormInputTypeFor(attribute) {
      let type = attribute.type;
      if (type === "STRING") {
        return "text";
      } else if (type === "INTEGER" || type === "FLOAT") {
        return "number"
      } else if (type === "DATETIME") {
        return "date"
      }
      return "text";
    },

    toJSDate(timestamp) {
      return this.$moment(timestamp).format("YYYY-MM-DD");
    }
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
    this.getFormData();
  }
}
</script>

<style scoped>
</style>

