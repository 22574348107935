import { render, staticRenderFns } from "./RelatedEntities.vue?vue&type=template&id=a410b988&scoped=true&"
import script from "./RelatedEntities.vue?vue&type=script&lang=js&"
export * from "./RelatedEntities.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a410b988",
  null
  
)

export default component.exports