<template>
  <div class="mx-auto w-50 mt-3">
    <div v-if="isEdit"><h3>Edit Event Type</h3></div>
    <div v-else><h3>Add Event Type</h3></div>
    <b-alert v-model="showAlert" class="mt-3" variant="danger" dismissible>{{ alertMessage }}</b-alert>
    <b-form class="mt-2" @submit.prevent="onSubmit" @reset.prevent="onReset">
      <b-form-group
        id="input-group-1"
        label="Name* (ID)"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.name"
          type="text"
          required
          placeholder="Enter name"
          :disabled="isEdit"
        ></b-form-input>   
      </b-form-group>
      <b-form-group
        id="input-group-2"
        label="Display name"
        label-for="input-1"
      >
        <b-form-input
          id="input-2"
          v-model="form.display_name"
          type="text"
          placeholder="Enter display name"
        ></b-form-input>   
      </b-form-group>
      <div class="mb-3"><h5>Attribute definitions</h5></div>
      <template v-if="entityType">
        <div :key="attrDef.name" v-for="attrDef in entityType.attributes">
          <AddEditAttributeDef ref="attrDefs" v-if="entityType" :isEdit=true :attributeDef="attrDef" /> 
        </div>
      </template>

      <div :key="attr.id" v-for="attr in attributes">
        <AddEditAttributeDef ref="attrDefs" :attrId=attr.id :isEdit="isEdit" @delete="handleDeleted" />
      </div>

      <b-button variant="light-outline" class="mt-2"
        @click="onAttrDefAdded"
      >
        <b-icon scale="1.2" icon="plus-circle"></b-icon>
      </b-button>

      <div class="mt-3">
        <b-button type="submit" variant="primary">Submit</b-button>
        &nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </div>
    </b-form>
  </div>  
</template>

<script>
import axios from 'axios';

import AddEditAttributeDef from '../components/AddEditAttributeDef.vue'

export default {
  name: 'AddEditEventTypePage',
  
  components: {
    AddEditAttributeDef,   
  },

  data() {
      return {
        attributes: [],
        attrCount: 0,

        form: {
          name: '',
          display_name: ''
        },
        showAlert: false,
        alertMessage: '',

        isEdit: 'id' in this.$route.params,
        entityType: null
      }
  },

  methods: {
    getData(entityId) {
      axios.get('/api/eventtype/' + entityId)
        .then((res) => {
          this.entityType = res.data.data;
          this.form.name = this.entityType.id;
          this.form.display_name = this.entityType.display_name;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    onAttrDefAdded() {
      this.attributes.push( { id: this.attrCount++ } );
    },

    onSubmit() {
      let localAttrData = []
      if (this.$refs.attrDefs) {
        for (let attrDef of this.$refs.attrDefs) {
          let attrFormData = attrDef.getFormData();
          if (attrFormData) {
            localAttrData.push(attrFormData);
          } else {
            console.warn('invalid attribute form data');
            return;
          }
        }
      }
      
      var formData = this.$data.form;
      formData.attributes = localAttrData;

      var path = this.isEdit ? '/api/eventtypes/update' : '/api/eventtypes/add';
      axios.post(path, formData)
        .then((res) => {
          if (res.data.status == "ok") {
            this.$router.push('/admin');
          } else {
            this.alertMessage = res.data.error_message;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          this.alertMessage = error;
          this.showAlert = true;
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    
    onReset() {
      // Reset our form values
      this.alertMessage = ''
      this.showAlert = false
      this.attributes = []
      this.attrCount = 0;
    },

    handleDeleted(attrId) {
      const index = this.attributes.findIndex(e => e.id === attrId)
      this.attributes.splice(index, 1);
    },
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
    if (this.isEdit) {
      this.getData(this.$route.params.id);
    }
  },

  watch: {
  }
}
</script>

<style scoped>
</style>

