<template>
  <b-container class="mt-3" fluid="md">
    
    <b-row>
      <b-col>
        <h3 v-if="entity">{{ entity.name }}</h3>
        <div v-if="entity">
          <router-link :to="`/entity/${entity.id}/edit`" class="mr-3">Edit</router-link>
          <router-link to="/" class="mr-3">Link to</router-link>
          <router-link to="/">Create event</router-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8" sm="12">
        <template v-if="entity">
          <AttributeTable v-if="hasAttributes" class="mt-4" :entity="entity" />
          <Payload class="mt-4" type="Entity" :entity="entity"/>
        </template>
      </b-col>
      <b-col>
        <template v-if="entity">
          <RelatedEntities class="mt-4" :entity="entity" />
          <RelatedEvents class="mt-4" :entity="entity" />
        </template>
      </b-col>
    </b-row>
  </b-container>  
</template>

<script>
import axios from 'axios';

import AttributeTable from '../components/AttributeTable.vue'
import Payload from '../components/Payload.vue'
import RelatedEntities from '../components/RelatedEntities.vue'
import RelatedEvents from '../components/RelatedEvents.vue'

export default {
  name: 'EntityPage',
  
  data () {
    return {
      entity:  null,
      hasAttributes: false, 
    };
  },

  components: { 
    AttributeTable,
    Payload,
    RelatedEntities,
    RelatedEvents
  },

  methods: {
    getEntity() {
      let entityId = this.$route.params.id;
      axios.get('/api/entity/' + entityId)
        .then((res) => {
          this.entity = res.data.data;
          this.hasAttributes = Object.keys(this.entity.attr_values).length > 0;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    }
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    currentUniverse () {
      return this.$store.getters.currentUniverse;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
    this.getEntity();
  }
}
</script>

<style scoped>
</style>

