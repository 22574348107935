<template>
  <div class="mx-auto w-50 mt-3">
    <div v-if="isEdit"><h3>Edit Entity</h3></div>
    <div v-else><h3>Add Entity</h3></div>
    <b-alert v-model="showAlert" class="mt-3" variant="danger" dismissible>{{ alertMessage }}</b-alert>
    <b-form class="mt-1" @submit.prevent="onSubmit" @reset.prevent="onReset">
      <b-form-group
        id="input-group-1"
        label="Name"
        label-for="name-input"
      >
        <b-form-input
          v-model="name"
          placeholder="Enter entity name"
          type="text"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-2"
        label="Type"
        label-for="type_select"
      >
        <b-form-select
          id="type-select"
          v-model="selected"
          :options="options"
          :disabled="isEdit"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>-- Select an entity type --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <div v-if="selected">
      <b-form-group
        :id="`input-group-${attribute.name}`"
        :label="attribute.display_name + (attribute.required === 'True' ? '*' : '')"
        :label-for="`input-${attribute.name}`"
        v-for="attribute in entityTypes[selected]"
        :key="attribute.name"
      >
        <b-form-input
          v-model="attributeValues[selected][attribute.name]"
          :id="`input-${attribute.name}`"
          :type="getFormInputTypeFor(attribute)"
          :required="attribute.required === 'True'"
          :placeholder="`Enter ${attribute.display_name.toLowerCase()}`"
        ></b-form-input>
      </b-form-group>
      </div>

      <b-button type="submit" variant="primary">Submit</b-button>
      &nbsp;
      <b-button type="reset" variant="danger">Reset</b-button>
      &nbsp;
      <b-link v-if="isEdit" @click="onDeleteClick">Delete</b-link>
    </b-form>
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddEditEntityPage',
  
  components: {   
  },

  data() {
      return {
        name: null,
        selected: null,
        options: [],
        entityTypes: {},
        attributeValues: {},
        showAlert: false,
        alertMessage: '',

        isEdit: 'id' in this.$route.params,
        entity: null
      }
  },

  methods: {
    onSubmit() {
      var path = this.isEdit 
        ? '/api/entity/' + this.$route.params.id + '/update'
        : '/api/entities/create';
      var formData = {
        'name' : this.name,
        'type' : this.selected,
        'attr_values' : this.attributeValues[this.selected]
      }
      axios.post(path, formData)
        .then((res) => {
          if (res.data.status == "ok") {
            let redirPath = this.isEdit 
              ? '/entity/' + this.$route.params.id
              : '/entity/' + res.data.data
            this.$router.push(redirPath);
          } else {
            this.alertMessage = res.data.error_message;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        });
    },
    
    onReset() {
      // Reset our form values
      this.name = null;
      this.selected = null;
      this.alertMessage = ''
      this.showAlert = false
    },

    getFormData() {
      axios.get('/api/entitytype/list')
        .then((res) => {
          let entityTypeOptions = [];
          for (let entityType of res.data.data) {
            entityTypeOptions.push({
              'value' : entityType.id,
              'text' : entityType.display_name
            });
            
            this.entityTypes[entityType.id] = entityType.attributes;
            // Set attribute defaults
            this.attributeValues[entityType.id] = {};
            //for (let attribute in entityType.attributes) {
            //  this.attributeValues[entityType.id][attribute] = null;
            //}
          }
          this.$data.options = entityTypeOptions;

          // If isEdit load entity data
          if (this.isEdit) {
            axios.get('/api/entity/' + this.$route.params.id)
              .then((res) => {
                if(res.data.status == "ok") {
                  this.entity = res.data.data;
                  this.name = this.entity.name;
                  this.selected = this.entity.type;
                  // Fill attributes
                  for (let attr_name in this.entity.attr_values) {
                    let attr_value = this.entity.attr_values[attr_name];
                    this.attributeValues[this.selected][attr_name] = attr_value;
                  }
                } else {
                  this.alertMessage = res.data.error_message;
                  this.showAlert = true;
                }
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
              });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        });
    },

    onDeleteClick() {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(value => {
          if (!value) {
            return;
          }
          axios.post('/api/entity/' + this.$route.params.id + '/delete')
            .then((res) => {
              if (res.data.status == "ok") {
                this.$router.push('/');
              } else {
                // TODO: errro handling
                console.error(res.data.error_message);
              }
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
            });
        })
        .catch(err => {
          console.error(err);
        })
    },

    getFormInputTypeFor(attribute) {
      let type = attribute.type;
      if (type === "STRING") {
        return "text";
      } else if (type === "INTEGER" || type === "FLOAT") {
        return "number"
      } else if (type === "DATETIME") {
        return "date"
      }
      return "text";
    }
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
    this.getFormData();
  }
}
</script>

<style scoped>
</style>

