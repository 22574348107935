<template>
  <b-card 
    class="mb-2"
    header-text-variant="white"
    header-tag="header"
    header-bg-variant="dark"
  >
    <template #header>
        <h6 class="mb-0">{{ formatTimestamp(note.timestamp )}}</h6>
      </template>
    <b-card-text v-html="note.content"></b-card-text>
    <b-link @click="onEdit" class="card-link">Edit</b-link>
    <b-link @click="onDelete" class="card-link">Delete</b-link>
    
  </b-card>
</template>

<script>
export default {
  name: 'Note',
  props: {
    note: Object,
    index: Number
  },

  methods: {
    formatTimestamp(date) {
      return this.$moment(date).format("MMMM DD YYYY, h:mm:ss a");
    },

    onEdit() {
      this.$emit('edit', this.index);
    },

    onDelete() {
      this.$emit('delete', this.index);
    }
  }
}
</script>
