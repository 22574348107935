<template>
  <div class="mx-auto w-50 mt-3">
    <div v-if="isEdit"><h3>Edit Link</h3></div>
    <div v-else><h3>Add Link</h3></div>
    <b-alert v-model="showAlert" class="mt-3" variant="danger" dismissible>{{ alertMessage }}</b-alert>
    <b-form class="mt-1" @submit.prevent="onSubmit" @reset.prevent="onReset">
      <b-form-group
        id="input-group-1"
        label="Name (optional)"
        label-for="name-input"
      >
        <b-form-input
          v-model="form.name"
          placeholder="Enter link name"
          type="text"
        ></b-form-input>
      </b-form-group> 
      
      <b-form-group
        id="input-group-1"
        label="Type"
        label-for="type_select"
      >
        <b-form-select
          id="type-select"
          v-model="selected"
          :options="options"
          :disabled="isEdit"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>-- Select a link type --</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <div v-if="selected">
      <b-form-group
        :id="`input-group-${attribute.name}`"
        :label="attribute.display_name + (attribute.required === 'True' ? '*' : '')"
        :label-for="`input-${attribute.name}`"
        v-for="attribute in linkTypes[selected].attributes"
        :key="attribute.name"
      >
        <b-form-input
          v-model="attributeValues[selected][attribute.name]"
          :id="`input-${attribute.name}`"
          :type="getFormInputTypeFor(attribute)"
          :required="attribute.required === 'True'"
          :placeholder="`Enter ${attribute.display_name.toLowerCase()}`"
        ></b-form-input>
      </b-form-group>
      </div>

      <div v-if="selected">
        <h5>Entities</h5>
        <div v-if="linkTypes[selected].relation == 'DIRECTIONAL' 
          || linkTypes[selected].relation == 'BIDIRECTIONAL'">
          <b-form-select
            id="entity-1"
            :options="entityOptions"
            v-model="selectedEntity1"
            required
            :disabled="isEdit"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>-- Select the first entity --</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select
            class="mt-2"
            id="entity-2"
            :options="entityOptions"
            v-model="selectedEntity2"
            required
            :disabled="isEdit"
          >
            <template #first>
              <b-form-select-option :value="null" disabled>-- Select the second entity --</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div v-else>
          <b-form-select
            id="entity-multi"
            :options="entityOptions"
            v-model="selectedEntities"
            multiple
            :select-size="6"
            required
            :disabled="isEdit"
          >
          </b-form-select>
        </div>
      </div>
      <div class="mt-3">
        <b-button type="submit" variant="primary">Submit</b-button>
        &nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
        &nbsp;
        <b-link v-if="isEdit" @click="onDeleteClick">Delete</b-link>
      </div>
    </b-form>
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddEditLinkPage',
  
  components: {   
  },

  data() {
      return {
        form: {
          name: null,
        },
        selected: null,
        options: [],
        entityOptions: [],
        selectedEntity1: null,
        selectedEntity2: null,
        selectedEntities: [],
        linkTypes: {},
        attributeValues: {},
        showAlert: false,
        alertMessage: '',
        isEdit: 'id' in this.$route.params,
        entity: null
      }
  },

  methods: {
    onSubmit() {
      var path = this.isEdit 
        ? '/api/link/' + this.$route.params.id + '/update'
        : '/api/links/add';
      var formData = {
        'name' : this.form.name,
        'type' : this.selected,
        'attr_values' : this.attributeValues[this.selected]
      }
      let selectedEntities = []
      let relation = this.linkTypes[this.selected].relation;
      if (relation == 'DIRECTIONAL' || relation == 'BIDIRECTIONAL') {
        selectedEntities.push(this.selectedEntity1);
        selectedEntities.push(this.selectedEntity2);
      } else {
        selectedEntities = this.selectedEntities;
      }
      formData.entities = selectedEntities;
      axios.post(path, formData)
        .then((res) => {
          if (res.data.status == "ok") {
            this.$router.push('/');
          } else {
            this.alertMessage = res.data.error_message;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        });
    },
    
    onReset() {
      this.form.name = null;
      this.form.type = ''
      this.alertMessage = ''
      this.showAlert = false
    },

    getFormData() {  
      axios.get('/api/entities/list')
        .then((res) => {
          let entities = res.data.data;
          for (let entity of entities) {
            this.entityOptions.push({
              'value' : entity.id,
              'text' : entity.name
            });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        });

      axios.get('/api/linktype/list')
        .then((res) => {
          let linkTypeOptions = [];
          for (let linkType of res.data.data) {
            linkTypeOptions.push({
              'value' : linkType.id,
              'text' : linkType.display_name
            });
            
            this.linkTypes[linkType.id] = linkType;
            // Set attribute defaults
            this.attributeValues[linkType.id] = {};
          }
          this.$data.options = linkTypeOptions;

          // If isEdit load entity data
          if (this.isEdit) {
            axios.get('/api/link/' + this.$route.params.id)
              .then((res) => {
                if(res.data.status == "ok") {
                  this.entity = res.data.data;
                  this.form.name = this.entity.name;
                  this.selected = this.entity.type;
                  let linkType = this.linkTypes[this.selected];
                  
                  // Fill attributes
                  for (let attr_name in this.entity.attr_values) {
                    let attr_value = this.entity.attr_values[attr_name];
                    // TODO - per type parsing
                    if (linkType.attributes[attr_name].type === "DATETIME") {
                      this.attributeValues[this.selected][attr_name] = this.toJSDate(attr_value);
                    } else {
                      this.attributeValues[this.selected][attr_name] = attr_value;  
                    }
                  }
                  
                  // Fill selected
                  if (linkType.relation == 'DIRECTIONAL' || linkType.relation == 'BIDIRECTIONAL') {
                    this.selectedEntity1 = this.entity.entities[0];
                    this.selectedEntity2 = this.entity.entities[1];
                  } else {
                    for (let lent in this.entity.entities) {
                      this.selectedEntities.push(lent);
                    }
                  }
                } else {
                  this.alertMessage = res.data.error_message;
                  this.showAlert = true;
                }
              })
              .catch((error) => {
                console.error(error);
              })
              .finally(() => {
              });
          }

        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        });

      
    },

    getFormInputTypeFor(attribute) {
      let type = attribute.type;
      if (type === "STRING") {
        return "text";
      } else if (type === "INTEGER" || type === "FLOAT") {
        return "number"
      } else if (type === "DATETIME") {
        return "date"
      }
      return "text";
    },

    onDeleteClick() {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(value => {
          if (!value) {
            return;
          }
          axios.post('/api/link/' + this.$route.params.id + '/delete')
            .then((res) => {
              if (res.data.status == "ok") {
                this.$router.push('/');
              } else {
                // TODO: errro handling
                console.error(res.data.error_message);
              }
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
            });
        })
        .catch(err => {
          console.error(err);
        })
    },

    toJSDate(timestamp) {
      return this.$moment(timestamp).format("YYYY-MM-DD");
    }
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
    this.getFormData();
  }
}
</script>

<style scoped>
</style>

