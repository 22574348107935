<template>
  <div>
    <div><h5>Events</h5></div>
    <div v-if="events.length > 0">
      <router-link v-for="event in events" 
        :key="event.id" 
        :to="`/event/${event.id}`"
        class="mr-3"
      >
        {{ event.name }}
      </router-link> 
    </div>
    <div v-else><em>No events have been created yet</em></div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EventList',
  
  data () {
    return {
      events: []
    }
  },

  methods: {
    getData() {
      axios.get('/api/events/list')
        .then((res) => {
          this.events = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    }
  },

  created () {
    this.getData();
  }
}
</script>

<style scoped>
</style>

