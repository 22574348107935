<template>
  
  <b-navbar-nav v-if="loggedIn">
    <b-nav-item disabled right>{{ userName }}</b-nav-item>
    
    <b-nav-form right>
      <b-form-select size="sm"
        :options="universes"
        v-model="selected"
        @change="onUniverseSelected">
        <template #first>
          <b-form-select-option :value="null" disabled>-- Universe --</b-form-select-option>
        </template>
      </b-form-select>
    </b-nav-form>
    <b-nav-item v-on:click="onLogout">logout</b-nav-item>
  </b-navbar-nav>
  <b-navbar-nav v-else>
    <b-nav-item v-on:click="onLogin">login</b-nav-item>
  </b-navbar-nav>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserProfileNav',
  data() {
    return {
      selected: null,
      universes: []  
    };
  },
  methods: {
    onLogin() {
      this.$router.push('/login')
    },

    onLogout() {
      var path = '/api/logout';
      axios.get(path)
          .then((res) => {
            if (res.data.status == "ok") {
              this.$store.commit('changeSession', null)
              this.$data.selected = null;
              this.$data.universes = [];
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },

    init() {
      var path = '/api/session';
      axios.get(path)
          .then((res) => {
            if (res.data.status == "ok") {
              let app_session = res.data.data;
              if (app_session.is_active) {
                let universes = []
                for (let universe of app_session.universes) {
                  universes.push({ value: universe.id, text: universe.name});
                }
                this.$data.universes = universes;
                if (app_session.current_universe) {
                  this.$data.selected = app_session.current_universe.id;
                }
                this.$store.commit("changeSession", app_session);
                this.$store.commit("setSessionDirty", false);
              } else {
                this.$store.commit("changeSession", null);
                this.$store.commit("setSessionDirty", false);
                this.$data.selected = null;
                this.$data.universes = [];
              }
            } else {
              console.error(res.data.error_message);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.showOverlay = false;
          });
    },

    onUniverseSelected(universeId) {
      var path = '/api/universe/select/' + universeId;
      axios.get(path)
          .then((res) => {
            if (res.data.status == "ok") {
              let session = res.data.data;
              this.$store.commit("changeSession", session);
              this.$router.go('/');
            } else {
              // TODO: use alert
              console.error(res.data.error_message);
            }
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.showOverlay = false;
          });
    }
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    currentUniverse () {
      return this.$store.getters.currentUniverse;
    },

    userName () {
      return this.$store.getters.userName;
    },

    session () {
      return this.$store.state.session;
    }
  },

  created() {
    this.init();
    this.unwatch = this.$store.watch(
      (state) => state.sessionDirty,
        (newValue, oldValue) => {
          if (!oldValue && newValue) {
            this.init();
          }
        });
  },

  beforeDestroy() {
    this.unwatch();
  },
}
</script>

<style scoped>
</style>