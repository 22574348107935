<template>
  <div>
    <div><h5>Entities</h5></div>
    <div v-if="entities.length > 0">
      <router-link v-for="entity in entities" 
        :key="entity.id" 
        :to="`/entity/${entity.id}`"
        class="mr-3"
      >
        {{ entity.name }}
      </router-link> 
    </div>
    <div v-else><em>No entities have been created yet</em></div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EntityList',
  
  data () {
    return {
      entities: []
    }
  },

  methods: {
    getData() {
      axios.get('/api/entities/list')
        .then((res) => {
          this.entities = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    }
  },

  created () {
    this.getData();
  }
}
</script>

<style scoped>
</style>

