<template>
  <div>
    <div><h5 v-if="entity">Related Entities</h5></div>
    <div v-if="relations">
      <div :key="relation.entity.id" v-for="relation in relations">
        <router-link
          :to="`/entity/${relation.entity.id}`">
          {{ relation.entity.name }}
        </router-link>
        (<router-link 
          :to="`/link/${relation.link.id}`"
        >
          {{ relation.link.type}}
        </router-link>)
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RelatedEntities',
  props: {
    entity: Object,
  },

  data () {
    return {
      relations: [],
    }
  },

  methods: {
    getData() {
      axios.get('/api/entity/' + this.entity.id + '/related/entities')
        .then((res) => {
          this.relations = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    }
  },

  created  () {
    this.getData();
  }
}
</script>

<style scoped>
</style>

