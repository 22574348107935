<template>
  <div v-if="entity">
    <div><h5>Attributes</h5></div>
    <b-table
      class="mt-3"
      head-variant="light"
      bordered
      small
      responsive="sm"
      :items="items"
      thead-class="hidden_header"
    ></b-table>
  </div>
</template>

<script>
export default {
  name: 'AttributeTable',
  props: {
    entity: Object,
  },

  data () {
    return {
      items: [],
    }
  },

  methods: {
    getData() {
      for (let attr in this.entity.attr_values) {
        this.items.push({
          'attribute' : attr,
          'value' : this.entity.attr_values[attr]
        })
      }
    }
  },

  created  () {
    this.getData();
  }
}
</script>

<style>
.hidden_header {
  display: none;
}
</style>

