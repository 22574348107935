<template>
  <div>
    <div><h5 v-if="entity">Related Events</h5></div>
    <div v-if="events">
      <div :key="event.id" v-for="event in events">
        <router-link :to="`/event/${event.id}`">
          {{ event.name }}
        </router-link> ({{ event.type}})
        
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RelatedEvents',
  props: {
    entity: Object,
  },

  data () {
    return {
      events: [],
    }
  },

  methods: {
    getData() {
      axios.get('/api/events/related/' + this.entity.id)
        .then((res) => {
          this.events = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    }
  },

  created  () {
    this.getData();
  }
}
</script>

<style scoped>
</style>

