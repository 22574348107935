import AddEditEntityPage from './pages/AddEditEntityPage'
import AddEditEntityTypePage from './pages/AddEditEntityTypePage'
import AddEditEventPage from './pages/AddEditEventPage'
import AddEditEventTypePage from './pages/AddEditEventTypePage'
import AddEditLinkTypePage from './pages/AddEditLinkTypePage'
import AddEditLinkPage from './pages/AddEditLinkPage'
import AddUniversePage from './pages/AddUniversePage'
import AdminPage from './pages/AdminPage'
import DefaultPage from './pages/DefaultPage.vue'
import EntityPage from './pages/EntityPage.vue'
import EventPage from './pages/EventPage.vue'
import LinkPage from './pages/LinkPage.vue'
import LogInPage from './pages/LogInPage.vue'
import Content from './components/Content.vue'

const routes = [
    { 
        path: '/',
        component: DefaultPage
    },
    { 
        path: '/admin',
        component: AdminPage
    },
    // Login
    { 
        path: '/login',
        component: LogInPage
    }, 
    // Entity
    {
        path: '/entity/add',
        component: AddEditEntityPage
    },
    {
        path: '/entity/:id/edit',
        component: AddEditEntityPage
    },
    {
        path: '/entity/:id',
        component: EntityPage
    },
    // Entity type
    {
        path: '/entitytype/add',
        component: AddEditEntityTypePage
    },
    {
        path: '/entitytype/edit/:id',
        component: AddEditEntityTypePage
    },
    // Event
    {
        path: '/event/add',
        component: AddEditEventPage
    },
    {
        path: '/event/:id/edit',
        component: AddEditEventPage
    },
    {
        path: '/event/:id',
        component: EventPage
    },    
    // Event type
    {
        path: '/eventtype/add',
        component: AddEditEventTypePage
    },
    {
        path: '/eventtype/edit/:id',
        component: AddEditEventTypePage
    },
    // Link
    {
        path: '/link/add',
        component: AddEditLinkPage
    },
    {
        path: '/link/:id/edit',
        component: AddEditLinkPage
    },
    {
        path: '/link/:id',
        component: LinkPage
    },    
    // Link type
    {
        path: '/linktype/add',
        component: AddEditLinkTypePage
    },
    {
        path: '/linktype/edit/:id',
        component: AddEditLinkTypePage
    },
    // Universe
    {
        path: '/universe/add',
        component: AddUniversePage
    },
    {
        path: '/test',
        component: Content
    },
    // 404
    //{
    //    path: '*',
    //    component: NotFoundPage
    //}
  ];

export default routes