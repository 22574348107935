<template>
  <b-container class="mt-3" fluid="md">
    <b-row>
      <b-col>
        <div v-if="entity">
          <div v-if="entity.name">
            <h3 v-if="entity.name">{{ entity.name }}</h3>
            <h6>{{ entity.type }}</h6>
          </div>
          <h3 v-else>{{ entity.type }}</h3>
        </div>
        <div v-if="entity">
          <router-link :to="`/link/${entity.id}/edit`" class="mr-3">Edit</router-link>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="8" sm="12">
        <template v-if="entity">
          <AttributeTable v-if="hasAttributes" class="mt-4" :entity="entity" />
          <Payload class="mt-4" type="Entity" :entity="entity"/>
        </template>
      </b-col>
      <b-col>
        <template v-if="entity">
          <RelatedLinkEntities class="mt-4" :entity="entity" />
       </template>
      </b-col>
    </b-row>
  </b-container>  
</template>

<script>
import axios from 'axios';

import AttributeTable from '../components/AttributeTable.vue'
import Payload from '../components/Payload.vue'
import RelatedLinkEntities from '../components/RelatedLinkEntities.vue'

export default {
  name: 'LinkPage',
  
  data () {
    return {
      entity:  null,
      hasAttributes: false, 
    };
  },

  components: { 
    AttributeTable,
    Payload,
    RelatedLinkEntities,
  },

  methods: {
    getEntity() {
      axios.get('/api/link/' + this.$route.params.id)
        .then((res) => {
          this.entity = res.data.data;
          this.hasAttributes = Object.keys(this.entity.attr_values).length > 0;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        });
    },
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    currentUniverse () {
      return this.$store.getters.currentUniverse;
    },

    userName () {
      return this.$store.getters.userName;
    },
  },

  created() {
    this.getEntity();
  }
}
</script>
