<template>
  <div>
  <b-navbar type="dark" variant="dark" toggleable="md">
    <b-navbar-brand href="/">RM</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item
          :disabled="currentUniverse == null"
          @click="navigate('/')">
          Home
        </b-nav-item>
        <b-nav-item
          :disabled="currentUniverse == null"
          @click="navigate('/timeline')">
          Timeline
        </b-nav-item>
        <b-nav-item-dropdown text="Add">
          <b-dropdown-item :disabled="!loggedIn || currentUniverse == null" 
            @click="navigate('/entity/add')">Entity</b-dropdown-item>
          <b-dropdown-item :disabled="!loggedIn || currentUniverse == null"
            @click="navigate('/link/add')">Link</b-dropdown-item>
          <b-dropdown-item :disabled="!loggedIn || currentUniverse == null"
            @click="navigate('/event/add')">Event</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item
          :disabled="!loggedIn"
          @click="navigate('/admin')"
        >
          Admin
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <UserProfileNav />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
import UserProfileNav from './UserProfileNav.vue'

export default {
  name: 'Navbar',
  components: {
    UserProfileNav
  },

  methods: {
    navigate(path) {
      this.$router.push(path).catch(()=>{});
    }
  },

  computed: {
    loggedIn () {
      return this.$store.getters.loggedIn;
    },

    currentUniverse () {
      return this.$store.getters.currentUniverse;
    },
  },
}
</script>

<style scoped>
</style>

