<template>
  <div>
    <div><h5>Event Types</h5></div>
    <div v-if="event_types.length > 0">
      <router-link v-for="event_type in event_types" 
        :key="event_type.id" 
        :to="`/eventtype/edit/${event_type.id}`"
        class="mr-3"
      >
        {{ event_type.display_name }}
      </router-link> 
    </div>
    <div v-else><em>No link types have been created yet</em></div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EventTypeList',
  
  data () {
    return {
      event_types: []
    }
  },

  methods: {
    getData() {
      axios.get('/api/eventtypes/list')
        .then((res) => {
          this.event_types = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    }
  },

  created () {
    this.getData();
  }
}
</script>

<style scoped>
</style>

