<template>
  <div>
    <b-alert v-model="showAlert" class="mb-2" variant="danger" dismissible>{{ alertMessage }}</b-alert>
    <b-overlay :show="showOverlay">
      <vue-editor v-model="content"></vue-editor>
    </b-overlay>
  </div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from "vue2-editor";

export default {
  name: 'AddEditNote',

  components: {
    VueEditor
  },
  
  props: {
    entityId : Number,
    type: String,
    isEdit: Boolean,
    editNote: Object
  },

  data() {
    return {
      content: null,
      showOverlay: false,
      showAlert: false,
      alertMessage: '',
    }
  },

  methods: {
    loadContent() {
      if (this.isEdit) {
        this.content = this.editNote.content;
      } else {
        this.content = '';
      }
    },

    getPath() {
      let action = this.isEdit ? 'edit' : 'add';
      if (this.type === 'Entity') {
        return '/api/entity/' + this.entityId + '/notes/' + action ;
      } else if (this.type === 'Event') {
        return '/api/event/' + this.entityId + '/notes/' + action;
      } else {
        return '/api/link/' + this.entityId + '/notes/' + action;
      }
    },

    saveNote() {
      this.showOverlay = true;
      let path = this.getPath();

      let data = { 'content' : this.content};
      if (this.isEdit) {
        data['timestamp'] = this.editNote.timestamp;
      }

      axios.post(path, data)
        .then((res) => {
          if (res.data.status == "ok") {
            this.$emit('noteAdded', res.data.data);
          } else {
            this.alertMessage = res.data.error_message;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          this.alertMessage = error;
          this.showAlert = true;
        })
        .finally(() => {
          this.showOverlay = false;
        });
    }
  },

  created() {
    this.loadContent();
  }

}
</script>

<style scoped>
</style>
