<template>
  <b-form ref="form" inline class="mt-2" @submit.prevent @reset.prevent>
    <b-form-input
        :id="`ad-form-name-${attrId}`"
        class="mb-2 mr-sm-2 mb-sm-0"
        placeholder="Name / ID"
        v-model="form.name"
        required
        :disabled="isEdit && (attributeDef != null)"
    ></b-form-input>
    <b-form-select 
      :id="`ad-form-type-${attrId}`"
      v-model="form.type" 
      class="mb-2 mr-sm-2 mb-sm-0"
      required
      :disabled="isEdit && (attributeDef != null)">
      <b-form-select-option :value="null" disabled>Type</b-form-select-option>
      <b-form-select-option value="STRING">String</b-form-select-option>
      <b-form-select-option value="INTEGER">Integer</b-form-select-option>
      <b-form-select-option value="FLOAT">Float</b-form-select-option>
      <b-form-select-option value="BOOL">Boolean</b-form-select-option>
      <b-form-select-option value="DATETIME">Date</b-form-select-option>
    </b-form-select>
    <b-form-input
        :id="`ad-form-dname-${attrId}`"
        class="mb-2 mr-sm-2 mb-sm-0"
        placeholder="Display name"
        v-model="form.display_name"
    ></b-form-input>
    <b-form-checkbox
        :id="`ad-form-required-${attrId}`"
        value="True"
        unchecked-value="False"
        v-model="form.required"
        :disabled="isEdit"
    >
    Required
    </b-form-checkbox>
    <a v-if="!attributeDef"
      href="javascript: void(0)" 
      @click="onDelete"
    >
      <b-icon-trash class="ml-2" />
    </a>
  </b-form>
</template>

<script>
export default {
  name: 'AddEditAttributeDef',
  
  props: {
      attrId : Number,
      isEdit : Boolean,
      attributeDef: Object
  },

  data() {
      return {
        form: {
            type: this.$props.isEdit && (this.$props.attributeDef != null) 
              ? this.$props.attributeDef.type : null,
            name : this.$props.isEdit && (this.$props.attributeDef != null)
              ? this.$props.attributeDef.name : '',
            display_name: this.$props.isEdit && (this.$props.attributeDef != null)
              ? this.$props.attributeDef.display_name : '',
            required: this.$props.isEdit && (this.$props.attributeDef != null)
              ? this.$props.attributeDef.required : false,
        } 
      }
  },

  methods: {
      getFormData() {
          if (!this.$refs.form.checkValidity()) {
              this.$refs.form.reportValidity();
              return
          } else {
            return this.$data.form;
          }
      },

      onDelete() {
        this.$emit('delete', this.$props.attrId);
      }
  },

}
</script>

<style scoped>
</style>

