<template>
  <div>
    <div><h5>Timeline</h5></div>
    <div><em>Not supported yet</em></div>
  </div>
</template>

<script>
export default {
  name: 'Timeline',
  props: {
  }
}
</script>

<style scoped>
</style>

