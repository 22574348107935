<template>
  <div>
    <div><h5 v-if="entity">Related Entities</h5></div>
    <div v-if="entities">
      <div :key="relatedEntity.id" v-for="relatedEntity in entities">
        <router-link :to="`/entity/${relatedEntity.id}`">
          {{ relatedEntity.name }}
        </router-link> ({{ relatedEntity.type}})
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RelatedEventEntities',
  props: {
    entity: Object,
  },

  data () {
    return {
      entities: [],
    }
  },

  methods: {
    getData() {
      axios.get('/api/event/' + this.entity.id + '/related/entities')
        .then((res) => {
          this.entities = res.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    }
  },

  created  () {
    this.getData();
  }
}
</script>

<style scoped>
</style>

