<template>
  <b-container class="mt-3"
  >
    <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
      <div :key="attr.id" v-for="attr in attributes">
        <AddEditAttributeDef ref="attrDefs" :attrId=attr.id @delete="handleDeleted" />
      </div>
    
    <b-button class="mt-3" @click="onAttrDefAdded()">Add</b-button>&nbsp;
    <div class="mt-3">
      <b-button type="submit" variant="primary">Submit</b-button>&nbsp;
      <b-button type="reset" variant="secondary">Reset</b-button>
    </div>
    </b-form>

    <div>Data: {{ attrData }} </div>
  </b-container>
</template>

<script>
import AddEditAttributeDef from './AddEditAttributeDef.vue'

export default {
  
  name: 'Content',
  
  data () {
    return {
      attributes: [],
      attrCount: 0,
      attrData: []
    }
  },

  components: {  
    AddEditAttributeDef, 
  },

  methods: {
    onAttrDefAdded() {
      this.attributes.push( { id: this.attrCount++ } );
    },

    onSubmit() {
      let localAttrData = []
      if (this.$refs.attrDefs) {
        for (let attrDef of this.$refs.attrDefs) {
          let formData = attrDef.getFormData();
          if (formData) {
            localAttrData.push(attrDef.$data.form); // why not formdata?
            // TODO: do something with the data
          } else {
            this.$data.attrData = []
            return;
          }
          this.$data.attrData = localAttrData;
        }
      }
      
    },

    handleDeleted(attrId) {
      const index = this.attributes.findIndex(e => e.id === attrId)
      this.attributes.splice(index, 1);
    },

    onReset() {
      this.attributes = []
      this.attrData = []
      this.attrCount = 0;
    }
  
  },
}
</script>

<style scoped>
</style>

